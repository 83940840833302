@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.category-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.1rem 0;
}
.category-list > .cat-item {
  width: 33.3333333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.1rem;
}
.category-list > .cat-item > .inner {
  text-align: center;
}
.category-list > .cat-item > .inner > .img-obj {
  margin: 0 auto;
  background: center no-repeat;
  background-size: cover;
  width: 1rem;
  height: 1rem;
}
.category-list > .cat-item > .inner > .label {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-top: 0.1rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.fulfil-give-box > .out-show {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.fulfil-give-box > .out-show > .label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.2rem 0 0.2rem 0.2rem;
}
.fulfil-give-box > .out-show > .list {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.fulfil-give-list > .list-item > .label {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.fulfil-give-list > .list-item > .label > .item-tag {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: inline-block;
  border: 1px solid #f90;
  border: 1px solid var(--color-second, #f90);
  color: #f90;
  color: var(--color-second, #f90);
  padding: 0 0.1rem;
  font-size: 85%;
  border-radius: 0.1rem;
  margin-right: 0.1rem;
}
.fulfil-give-list > .list-item > .label > .list {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.fulfil-give-list > .list-item > .label > .list > .list-item {
  padding: 0;
}
.fulfil-give-list > .list-item > .label > .list > .list-item + .list-item {
  margin-top: 0.2rem;
  border-top: 0;
}
.fulfil-give-list > .list-item > .label > .list > .list-item > .label {
  white-space: normal;
  word-break: break-all;
}
.popup.fulfil-give-box-popup {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.popup.fulfil-give-box-popup > .inner {
  width: 100%;
  background-color: #fff;
  max-height: 80vh;
}
.popup.fulfil-give-box-popup > .inner > .body {
  padding: 0;
}
.popup.fulfil-give-box-popup > .inner > .body > .freeFreight-wrap > .item {
  padding: 0.2rem;
}
.popup.fulfil-give-box-popup > .inner > .body > .freeFreight-wrap > .item + .item {
  border-top: 1px solid #f1f1f1;
}
.popup.fulfil-give-box-popup > .inner > .body > .freeFreight-wrap > .item > .price {
  font-size: 120%;
  font-weight: bold;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.footer[data-v-6fb758d0] {
  background-color: #fff;
}
.footer > .cart[data-v-6fb758d0] {
  color: #868e96;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  position: relative;
}
.footer > .cart em[data-v-6fb758d0] {
  font-style: normal;
  font-size: 75%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f00;
  color: #fff;
  line-height: 1.5;
  min-width: 1.5em;
  text-align: center;
  border-radius: 100rem;
}
.second-footer[data-v-6fb758d0] {
  background-color: #ffcccc;
  line-height: 2;
  color: #f00;
  text-align: center;
  font-size: 80%;
  min-height: inherit;
}
.share-earn[data-v-6fb758d0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem 0;
  background-color: #ffeff0;
  border: 0.05rem solid #f00;
  border-radius: 0.1rem;
}
.share-earn + .share-earn[data-v-6fb758d0] {
  margin-top: 0.2rem;
}
.share-earn > .inner[data-v-6fb758d0] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-right: 0.05rem dashed #f00;
  font-weight: bold;
}
.share-earn > .inner[data-v-6fb758d0],
.share-earn > .inner .price[data-v-6fb758d0] {
  color: #f00;
}
.share-earn > .inner .commission-list[data-v-6fb758d0] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}
.share-earn > .inner .commission-list > .badge + .badge[data-v-6fb758d0] {
  margin-top: 0.1rem;
}
.share-earn > .inner .commission-list > .dividing-line[data-v-6fb758d0] {
  border-bottom: 0.05rem dashed #f00;
  height: 0;
  margin: 0.2rem 0;
}
.share-earn > .btn[data-v-6fb758d0] {
  margin: 0 0.2rem;
}
.share-income[data-v-6fb758d0] {
  padding: 0.2rem;
}
.share-income > .inner[data-v-6fb758d0] {
  padding: 0.2rem;
  background-color: #ffeff0;
  border-radius: 0.1rem;
}
.share-income > .inner > .body[data-v-6fb758d0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.1rem;
}
.share-income > .inner > .body > .info > .label[data-v-6fb758d0] {
  color: #f00;
}
.share-income > .inner > .body > .info > .value > .price[data-v-6fb758d0] {
  color: #f00;
  font-size: 0.6rem;
  font-weight: bold;
  margin-right: 0.1rem;
}
.share-income > .inner > .body > .share-btn[data-v-6fb758d0] {
  width: 1rem;
  height: 1rem;
  background-color: #f00;
  color: #fff;
  border-radius: 100vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1;
}
.share-income > .inner > .footer[data-v-6fb758d0] {
  background-color: transparent;
  padding-top: 0.1rem;
  border-top: 1px dashed #f00;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.goods-swiper > .swiper-container {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.goods-swiper > .sold-out {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon02.879e3bae.png) center no-repeat;
  background-size: 70%;
  width: 100%;
  height: 100%;
}
.goods-info {
  background-color: #fff;
}
.goods-info > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.goods-info > .header > .label,
.goods-info > .header > .price-wrap,
.goods-info > .header > .countdown-wrap {
  min-height: 1rem;
  padding: 0 0.2rem;
}
.goods-info > .header > .label {
  -webkit-box-flex: 0.4;
  -webkit-flex: 0.4 0.4 1%;
          flex: 0.4 0.4 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
  color: #fff;
  /*font-size: 120%;*/
}
.goods-info > .header > .price-wrap {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
}
.goods-info > .header > .price-wrap .price {
  color: #fff;
  line-height: 1.2;
}
.goods-info > .header > .price-wrap > .price {
  font-size: 0.4rem;
}
.goods-info > .header > .price-wrap > .old-price {
  color: #fff;
  font-size: 0.22rem;
  line-height: 1.2;
}
.goods-info > .header > .countdown-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffcc33;
}
.goods-info > .header > .countdown-wrap > .countdown {
  font-size: 0.3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #f00;
}
.goods-info > .body {
  padding: 0.1rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.goods-info > .body > .label-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.goods-info > .body > .label-wrap > .name {
  font-size: 0.32rem;
}
.goods-info > .body > .label-wrap > .desc {
  color: #868e96;
  line-height: 1.2;
  margin-top: 0.2rem;
}
.goods-info > .body > .share {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.22rem;
  color: #868e96;
}
.goods-info > .body > .share > .f-icon {
  font-size: 0.6rem;
  line-height: 1;
  margin-bottom: 0.1rem;
}
.goods-info > .body > .share > .label {
  white-space: nowrap;
}
.goods-info > .body > .share.active {
  color: #e52779;
  color: var(--color-main, #e52779);
}
.goods-info > .footer {
  padding: 0.1rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.goods-info > .footer .rise-tips {
  display: inline-block;
  padding: 0 0.1rem;
  margin-left: 0.1rem;
  line-height: 1.6;
  border: 1px solid #f1f1f1;
  border-radius: 0.667vw;
  color: #999;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.review-detail {
  padding: 0.2rem;
  background-color: #fff;
}
.review-detail > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.review-detail > .header > .user {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.review-detail > .header > .user > .avatar {
  margin-right: 0.1rem;
}
.review-detail > .header > .user > .avatar > .inner {
  width: 0.8rem;
  height: 0.8rem;
  background: transparent center no-repeat;
  background-size: cover;
  border-radius: 100vw;
}
.review-detail > .header > .user > .info > .review-time {
  color: #868e96;
  font-size: 80%;
}
.review-detail > .header > .extra {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}
.review-detail > .body {
  padding: 0.1rem 0;
}
.review-detail > .body > .spec {
  display: inline-block;
  padding: 0.1rem;
  border: 1px solid #f90;
  border: 1px solid var(--color-second, #f90);
  border-radius: 1.333vw;
  color: #f90;
  color: var(--color-second, #f90);
  font-size: 80%;
  line-height: 1.2;
  margin-top: 0.1rem;
}
.review-detail > .body > .content {
  padding: 0.1rem 0;
  word-break: break-all;
}

/*# sourceMappingURL=goods.0026a108.css.map*/